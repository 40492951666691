<template>
  <div class="pixel-list-wrap">
    <div class="header dp-flex justify-btw">
      <ul class="dp-flex">
        <li v-for="(item, index) in showItems" :key="index">
          <p class="title text-a-c f-12">{{ item.title }}</p>
          <p class="count text-a-c">
            <!-- <span v-if="index != 0">$</span> -->
            {{ resData[item.prop] || "--" }}<span v-if="index == 0">个</span>
          </p>
        </li>
      </ul>
      <el-input
        class="align-self-start"
        style="width:300px;"
        placeholder="输入广告管理平台名称/ID搜索"
        v-model="keyword"
      >
        <el-button
          @click="handleSearch"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-input>
    </div>
    <cus-table
      ref="cusTableRef"
      :tableParams="tableParams"
      :tableHeightData="tableHeightData"
      :tableData="tableData"
      :colData="colData"
      :loading="loading"
    >
      <template v-slot:colslot="{ scope, prop }">
        <div v-if="prop == 'operate'">
          <el-button type="text" @click="bindEntity(scope.row)"
            >点击绑定主体</el-button
          >
        </div>
      </template>
    </cus-table>
    <!-- 绑定主体 -->
    <bind-entity
      :visible="bindEntityVisible"
      @close-dialog="bindEntityVisible = false"
      @comfirm-bind-entity="confirmBindEntity"
    ></bind-entity>
  </div>
</template>

<script>
import bindEntity from "./bindEntity";
import cusTable from "@/views/components/cusTable";
import mainPageAnalyze from "@/views/adAssets/components/mainPageAnalyze";
import { getMainPages } from "@/api/adAssets";
export default {
  components: {
    bindEntity,
    cusTable
  },
  props: ["isSync"],
  data() {
    return {
      page: 1,
      limit: 10,
      keyword: "",
      tableParams: {
        className: "",
        border: true,
        isTableHeight: true
      },
      // 表格
      tableHeightData: [
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" },
        { selector: ".content", cssProperty: "paddingBottom" },
        { selector: ".cus-header", cssProperty: "height" },
        { selector: ".cus-header", cssProperty: "marginTop" },
        { selector: ".cus-header", cssProperty: "marginBottom" }
      ],
      tableData: [
        {
          name: "商务管理平台1",
          id: "******1",
          field1: "field1",
          field2: "field2"
        },
        {
          name: "商务管理平台2",
          id: "******2",
          field1: "field11",
          field2: "field22"
        }
      ],
      colData: [
        { label: "名称", prop: "name" },
        {
          label: "ID",
          prop: "id"
        },
        {
          label: "属性1",
          prop: "field1"
        },
        {
          label: "属性2",
          prop: "field1"
        },
        {
          label: "操作",
          prop: "operate"
        }
      ],
      loading: false,
      // 接口返回数据
      resData: { total: 2 },
      // 表格上方数据总览
      showItems: [{ title: "总数", prop: "total" }],
      // 绑定主体
      bindEntityVisible: false
    };
  },
  methods: {
    // 搜索
    handleSearch() {
      this.page = 1;
      this.$message("开发中...");
    },
    bindEntity(row) {
      // console.log("bindEntity(row)",row)
      this.bindEntityVisible = true;
    },
    confirmBindEntity(fomrModel) {
      // console.log("confirmBindEntity(fomrModel)", fomrModel);
      this.$message("开发中...");
      // 调用接口提交表单
    }
  },
  mounted() {},
  watch: {
    // 当同步成功后重新调用列表接口
    isSync(val) {
      if (val) {
        // this.getMainPages();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.pixel-list-wrap {
  color: $theme-color;
  .header {
    margin-bottom: 8px;
    .el-input {
      width: auto;
    }
    li {
      min-width: 150px;
      border-right: 1px solid #e5e6e9;
      padding: 0 15px;
      &:last-child {
        border-right: none;
      }
    }
    .count {
      font-weight: 700;
      margin-top: 10px;
    }
  }
}
</style>