<template>
  <div class="all-assets-wrap">
    <div class="dp-flex justify-btw align-item-c">
      <cus-form
        class="search-form"
        ref="formRef"
        :formDatas="{ inline: true }"
        :formItemDatas="formItemDatas"
        :formModelFn="formModelFn"
        :formRulesFn="formRulesFn"
        :showSubmit="true"
        @submit-form="submitForm"
      ></cus-form>
      <el-button type="primary" @click="addEntity">添加主体</el-button>
    </div>
    <cus-table
      ref="cusTableRef"
      :tableParams="tableParams"
      :tableHeightData="tableHeightData"
      :tableData="tableData"
      :colData="colData"
      :loading="loading"
    >
      <template v-slot:colslot="{ scope, prop }">
        <div v-if="prop == 'urls'">
          <p v-for="(item, index) in scope.row[prop]" :key="index">
            {{ item }};
          </p>
        </div>
        <div v-else-if="prop == 'status'">
          <el-tooltip
            v-if="scope.row.reason"
            :content="scope.row.reason"
            placement="top"
          >
            <el-switch
              v-model="scope.row[prop]"
              :active-value="1"
              :inactive-value="0"
              @change="changeStatus"
            ></el-switch
          ></el-tooltip>
          <el-switch
            v-else
            v-model="scope.row[prop]"
            :active-value="1"
            :inactive-value="0"
            @change="changeStatus"
          ></el-switch>
        </div>
        <div v-else-if="prop == 'operate'">
          <el-button type="text" @click="editEntity(scope.row)">编辑</el-button>
          <el-button type="text" @click="delEntity(scope.row)">删除</el-button>
          <el-button type="text" @click="mergeEntity(scope.row)"
            >主体合并</el-button
          >
        </div>
      </template>
    </cus-table>
    <!-- comMethodsLy -->
    <com-methods-ly ref="comMethodsLy"></com-methods-ly>
    <!-- 主体合并弹窗 -->
    <merge-entity
      :visible="mergeEntityVisible"
      @close-dialog="mergeEntityVisible = false"
      @comfirm-merge-entity="confirmMergeEntity"
    ></merge-entity>
    <!-- 编辑-添加主体 -->
    <add-edit-entity
      :title="addEditEntityTitle"
      :visible="addEditEntityVisible"
      :initModel="this.addEditEntityTitle == '编辑主体' ? initModel : undefined"
      @close-dialog="addEditEntityVisible = false"
      @comfirm-entity="confirmEntity"
    ></add-edit-entity>
  </div>
</template>

<script>
import comMethodsLy from "@/views/components/comMethodsLy";
import cusTable from "@/views/components/cusTable";
import cusForm from "@/views/components/cusForm";
import mainPageAnalyze from "@/views/adAssets/components/mainPageAnalyze";
import { getMainPages } from "@/api/adAssets";
import mergeEntity from "./mergeEntity";
import addEditEntity from "./addEditEntity";
export default {
  components: {
    cusTable,
    cusForm,
    comMethodsLy,
    mergeEntity,
    addEditEntity
  },
  props: ["isSync"],
  data() {
    return {
      // 表单
      formItemDatas: [
        {
          label: "",
          type: "input",
          prop: "name",
          placeholder: "请输入公司名称"
        },
        {
          label: "",
          type: "input",
          prop: "url",
          placeholder: "请输入主域名"
        },
        {
          label: "",
          type: "select",
          prop: "areaId",
          placeholder: "请选择团队",
          options: []
        },
        {
          label: "",
          type: "select",
          prop: "status",
          placeholder: "请选择状态",
          options: [
            { id: 0, name: "禁用" },
            { id: 1, name: "启用" }
          ]
        }
      ],
      page: 1,
      limit: 10,
      keyword: "",
      tableParams: {
        className: "",
        border: true,
        isTableHeight: true
      },
      // 表格
      tableHeightData: [
        { selector: ".navbar", cssProperty: "height" },
        { selector: ".app-main", cssProperty: "paddingTop" },
        { selector: ".app-main", cssProperty: "paddingBottom" },
        { selector: ".content", cssProperty: "paddingBottom" },
        { selector: ".cus-header", cssProperty: "height" },
        { selector: ".cus-header", cssProperty: "marginTop" },
        { selector: ".cus-header", cssProperty: "marginBottom" }
      ],
      tableData: [
        {
          name: "公司名称1",
          urls: ["url1", "url2", "url3", "url4"],
          areaId: "团队ID1",
          status: 0,
          reason: "禁用原因"
        },
        {
          name: "公司名称2",
          urls: ["url5", "url6", "url7", "url8"],
          areaId: "团队ID2",
          status: 1,
          reason: ""
        }
      ],
      colData: [
        { label: "公司名称", prop: "name" },
        {
          label: "主域名列表",
          prop: "urls"
        },
        {
          label: "团队ID",
          prop: "areaId"
        },
        {
          label: "是否禁用",
          prop: "status"
        },
        {
          label: "操作",
          prop: "operate"
        }
      ],
      loading: false,
      requiredProps: [],
      // 主体合并
      mergeEntityVisible: false,
      currentRow: {},
      // 添加编辑主体
      addEditEntityTitle: "",
      addEditEntityVisible: false,
      initModel: {}
    };
  },
  computed: {
    formModelFn() {
      let obj = {};
      this.formItemDatas.forEach(item => {
        obj[item.prop] = "";
      });
      return obj;
    },
    formRulesFn() {
      let obj = {};
      let requiredItems = this.formItemDatas.filter(item => {
        return this.requiredProps.some(p => p == item.prop);
      });
      requiredItems.forEach(item => {
        obj[item.prop] = {
          required: true,
          message: item.placeholder,
          trigger: ["change"]
        };
      });
      return obj;
    }
  },
  methods: {
    submitForm(fomrModel) {
      // console.log("submitForm(fomrModel)", fomrModel);
      this.$message("开发中...");
      // 调用接口提交表单
    },
    // 搜索
    handleSearch() {
      this.page = 1;
      this.$message("开发中...");
    },
    // 查看更多
    lookMore(row, prop, status) {
      switch (status) {
        case "total":
          this.$message("开发中...");
          break;
        case "normal":
          this.$message("开发中...");
          break;
        case "abnormal":
          this.$message("开发中...");
          break;
        default:
          break;
      }
    },
    changeStatus(val) {
      // console.log("changeStatus-val", val);
      if (!val) {
        this.$prompt("请输入禁用原因", "禁用原因", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        })
          .then(({ value }) => {
            this.$message({
              type: "success",
              message: "禁用原因是: " + value
            });
            // 调用接口改变状态
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消输入"
            });
          });
      }
    },
    mergeEntity(row) {
      this.mergeEntityVisible = true;
      this.currentRow = row;
    },
    confirmMergeEntity(formModel) {
      // console.log("confirmMergeEntity-formModel", formModel); //formModel.entity
      // console.log("currentRow", this.currentRow); //currentRow.id
      // 调用接口提交表单-同时调用列表接口-成功后关闭弹窗//addEditVisible=false
      this.$message("开发中...");
    },
    delEntity(row) {
      let name = row.name;
      this.$refs.comMethodsLy.confirmDel(
        `确定删除“${name}”吗？`,
        "删除确认",
        () => {
          this.$message("开发中...");
        },
        `已取消删除“${name}”`
      );
    },
    editEntity(row) {
      this.addEditEntityVisible = true;
      this.addEditEntityTitle = "编辑主体";
      this.currentRow = row;
      this.initModel = { ...row };
      this.initModel.urls = row.urls.join(",");
    },
    addEntity() {
      this.addEditEntityVisible = true;
      this.addEditEntityTitle = "添加主体";
    },
    // 添加-编辑主体的确认
    confirmEntity(formModel) {
      // console.log("confirmEntity-formModel", formModel); //formModel.entity
      if (this.addEditEntityTitle == "编辑主体") {
        // 编辑时传入id
        // console.log("currentRow", this.currentRow); //currentRow.id
      }
      // 调用接口提交表单-同时调用列表接口
      this.$message("开发中...");
    }
  },
  mounted() {},
  watch: {
    // 当同步成功后重新调用列表接口
    isSync(val) {
      if (val) {
        // this.getMainPages();
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.all-assets-wrap {
  color: $theme-color;
  .header {
    margin-bottom: 8px;
    .el-input {
      width: auto;
    }
    li {
      min-width: 150px;
      border-right: 1px solid #e5e6e9;
      padding: 0 15px;
      &:last-child {
        border-right: none;
      }
    }
    .count {
      font-weight: 700;
      margin-top: 10px;
    }
  }
  .search-form {
    .el-form-item {
      margin: {
        top: 10px;
        bottom: 10px;
      }
    }
  }
}
</style>